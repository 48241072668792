import React, { useState, useEffect } from 'react';
import { DataType, Table, useTable } from 'ka-table';
import { SortingMode } from 'ka-table/enums';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import SearchInput from 'components/interface/searchInput';
import CampaignContactStatus from '../campaignContactStatus';
import CampaignContactsAddFromList from '../campaignContactsAddFromList';
import CampaignContactsUpload from '../campaignContactsUpload';
import CampaignContactsDownload from '../campaignContactsDownload';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';

import {
  type CampaignContact,
  type CampaignData,
} from 'features/campaigns/providers/CampaignProvider';

import { formatDateTime } from 'utils/dates';
import { formatNumber } from 'utils/numbers';

import { ActionType } from 'ka-table/enums';
import './styles.css';

type CampaignContactsProps = {
  campaignId: string;
  canUpdate: boolean;
};

/**
 * CampaignContacts
 * @description Component that displays the contacts of a campaign in a table
 * @param {string} campaignId - Campaign id
 * @param {boolean} canUpdate - If the user can update the campaign
 * @returns {TSX.Element} CampaignContacts component
 */

const CampaignContacts: React.FC<CampaignContactsProps> = ({
  campaignId,
  canUpdate,
}) => {
  const { campaignsData, getCampaignContacts, getCampaignStats } =
    useCampaign();

  const campaignData: CampaignData = campaignsData[campaignId];

  const [campaignContactsArray, setCampaignContactsArray] = useState<
    CampaignContact[]
  >([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [sortBy, setSortBy] = useState('lastUpdate');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [tableKey, setTableKey] = useState(Date.now());

  const table = useTable({
    onDispatch: async (action) => {
      if (action.type === ActionType.UpdatePageIndex) {
        setPageIndex(action.pageIndex);
      }
      if (action.type === ActionType.UpdateSortDirection) {
        setSortBy(action.columnKey);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      }
    },
  });

  useEffect(() => {
    const campaignContactsAux: CampaignContact[] = [];

    campaignData.campaignContacts.forEach((contact) => {
      campaignContactsAux.push({
        ...contact,
        retries: contact.retries + (contact.status != 'queued' ? 1 : 0),
      });
    });

    setCampaignContactsArray(campaignContactsAux);
    setTableKey(Date.now());
  }, [campaignData.campaignContacts]);

  useEffect(() => {
    const handleGetContacts = async () => {
      if (isLoading) return;
      setIsLoading(true);

      await getCampaignContacts(
        campaignId,
        pageIndex,
        pageSize,
        sortBy,
        sortOrder,
        searchTerm,
      );

      setIsLoading(false);
    };

    handleGetContacts();
  }, [pageIndex, pageSize, sortBy, sortOrder, searchTerm]);

  useEffect(() => {
    setPageCount(Math.ceil(campaignData.campaignContactsCount / pageSize));
  }, [campaignData.campaignContactsCount, pageSize]);

  return (
    <div>
      <div className={'campaign-contacts-header'}>
        <div className={'campaign-contacts-header-left'}>
          <div className={'campaign-contacts-header-left-icon'}>
            <Icon name={'users'} size={18} color={'var(--primary)'} />
          </div>
          <Text variant={'h7'}>
            {formatNumber(campaignData.campaignContactsCount)} Contactos
          </Text>
        </div>
        <div className={'campaign-contacts-header-right'}>
          <SearchInput onChange={(e: any) => setSearchTerm(e.target.value)} />
          {canUpdate && (
            <>
              <CampaignContactsAddFromList
                campaignId={campaignId}
                onSubmitCallback={() => {
                  setTimeout(() => {
                    setPageIndex(0);
                    getCampaignStats([campaignId]);
                  }, 1000);
                }}
              />
              <CampaignContactsUpload
                campaignId={campaignId}
                onSubmitCallback={() => {
                  setTimeout(() => {
                    setPageIndex(0);
                    getCampaignStats([campaignId]);
                  }, 1000);
                }}
              />
            </>
          )}
          <CampaignContactsDownload campaignId={campaignId} />
        </div>
      </div>
      <div className={'campaign-contacts-table'}>
        <Table
          key={tableKey}
          table={table}
          rowKeyField={'contact'}
          columns={[
            {
              key: 'fId',
              dataType: DataType.String,
              title: 'ID',
              style: { width: 80 },
            },
            {
              key: 'contact',
              dataType: DataType.String,
              title: 'Contacto',
              style: { width: 140 },
            },
            {
              key: 'name',
              dataType: DataType.String,
              title: 'Nombre',
              style: { width: 200 },
            },
            {
              key: 'context',
              dataType: DataType.String,
              title: 'Contexto',
            },
            {
              key: 'status',
              dataType: DataType.String,
              title: 'Estado',
              style: { width: 140 },
            },
            {
              key: 'retries',
              dataType: DataType.Number,
              title: 'Detonaciones',
              style: { width: 140, textAlign: 'center' },
            },
            {
              key: 'lastUpdate',
              dataType: DataType.Date,
              title: 'Última actualización',
              style: { width: 200, textAlign: 'right' },
            },
          ]}
          data={campaignContactsArray}
          loading={{
            enabled: isLoading,
          }}
          paging={{
            enabled: true,
            pageIndex: pageIndex,
            pageSize: pageSize,
            pagesCount: pageCount,
          }}
          childComponents={{
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'fId':
                    return (
                      <Text variant={'b3'} numberOfLines={1}>
                        {props.value}
                      </Text>
                    );
                  case 'contact':
                    return (
                      <Text variant={'b3'} color={'var(--gray)'}>
                        {props.value}
                      </Text>
                    );
                  case 'name':
                    return (
                      <Text variant={'b3'} numberOfLines={1}>
                        {props.value}
                      </Text>
                    );
                  case 'context':
                    return (
                      <Text
                        variant={'b3'}
                        color={'var(--gray)'}
                        numberOfLines={1}
                      >
                        {props.value}
                      </Text>
                    );
                  case 'status':
                    return (
                      <CampaignContactStatus status={props.value as string} />
                    );
                  case 'lastUpdate':
                    return (
                      <Text
                        variant={'b3'}
                        color={'var(--gray)'}
                        align={'right'}
                      >
                        {formatDateTime(props.value)}
                      </Text>
                    );
                }
              },
            },
          }}
          sortingMode={SortingMode.Single}
        />
      </div>
    </div>
  );
};

export default CampaignContacts;
