import React from 'react';

import Hr from 'components/interface/hr';
import Text from 'components/interface/text';
import Sheet from 'components/interface/sheet';
import AudioPlayerDetailed from 'components/interface/audioPlayerDetailed';

import { type CampaignCall } from 'features/campaigns/providers/CampaignProvider';

import { formatDateTimeWithSeconds, formatDuration } from 'utils/dates';

import './styles.css';

type CallDetailsProps = {
  call: CampaignCall | null;
  isOpen: boolean;
  onClose: () => void;
};

/**
 * CallDetails
 * @description Component to display the details of a call
 * @param {CampaignCall} call - Call object
 * @param {boolean} isOpen - Whether the sheet is open
 * @param {function} onClose - Function to close the sheet
 * @returns {TSX.Element} CallDetails component
 */

const CallDetails: React.FC<CallDetailsProps> = ({ call, isOpen, onClose }) => {
  return (
    <Sheet title={'Detalles de la llamada'} isOpen={isOpen} onClose={onClose}>
      <AudioPlayerDetailed url={call?.id} />
      <Hr marginTop={16} marginBottom={16} />
      <div className={'call-details-header'}>
        <Text variant={'h7'}>Metadatos de la llamada</Text>
        <Text variant={'b2'} color={'var(--gray-2)'}>
          {call?.id}
        </Text>
      </div>
      <div className={'call-details-metadata'}>
        <div className={'call-details-metadata-item'}>
          <Text variant={'b2'}>Número</Text>
          <Text variant={'b2'} color={'var(--gray)'}>
            {call?.numberTo}
          </Text>
        </div>
        <div className={'call-details-metadata-item'}>
          <Text variant={'b2'}>Tipo</Text>
          <Text variant={'b2'} color={'var(--gray)'}>
            {call?.type}
          </Text>
        </div>
        <div className={'call-details-metadata-item'}>
          <Text variant={'b2'}>Estado</Text>
          <Text variant={'b2'} color={'var(--gray)'}>
            {call?.status}
          </Text>
        </div>
        <div className={'call-details-metadata-item'}>
          <Text variant={'b2'}>Respondido por</Text>
          <Text variant={'b2'} color={'var(--gray)'}>
            {call?.answeredBy}
          </Text>
        </div>
        <div className={'call-details-metadata-item'}>
          <Text variant={'b2'}>Creada el</Text>
          <Text variant={'b2'} color={'var(--gray)'}>
            {formatDateTimeWithSeconds(call?.createdAt)}
          </Text>
        </div>
        <div className={'call-details-metadata-item'}>
          <Text variant={'b2'}>Empezó el</Text>
          <Text variant={'b2'} color={'var(--gray)'}>
            {formatDateTimeWithSeconds(call?.startedAt)}
          </Text>
        </div>
        <div className={'call-details-metadata-item'}>
          <Text variant={'b2'}>Termino el</Text>
          <Text variant={'b2'} color={'var(--gray)'}>
            {formatDateTimeWithSeconds(call?.endedAt)}
          </Text>
        </div>
        <div className={'call-details-metadata-item'}>
          <Text variant={'b2'}>Duró</Text>
          <Text variant={'b2'} color={'var(--gray)'}>
            {formatDuration(call?.duration ?? 0)}
          </Text>
        </div>
        <div className={'call-details-metadata-item'}>
          <Text variant={'b2'}>Consumido</Text>
          <Text variant={'b2'} color={'var(--gray)'}>
            {formatDuration(call?.billedDuration ?? 0)}
          </Text>
        </div>
      </div>
    </Sheet>
  );
};

export default CallDetails;
