import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import WaveSurfer from 'wavesurfer.js';

import Text from 'components/interface/text';
import IconButton from 'components/interface/iconButton';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { formatDuration } from 'utils/dates';

import './styles.css';

type AudioPlayerDetailedProps = {
  url?: string;
};

/**
 * AudioPlayerDetailed
 * @description Detailed audio player component
 * @param {string} url - Audio url
 * @returns {TSX.Element} Audio player component
 */

const AudioPlayerDetailed: React.FC<AudioPlayerDetailedProps> = ({ url }) => {
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const [loadingPercentage, setLoadingPercentage] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isWaveSurferReady, setIsWaveSurferReady] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const { getCallRecording } = useInbox();

  useEffect(() => {
    setIsWaveSurferReady(false);
    setLoadingPercentage(0);
    setCurrentTime(0);
    setError(null);
    setIsPlaying(false);

    if (wavesurferRef.current) {
      wavesurferRef.current.destroy();
      wavesurferRef.current = null;
    }

    const initializeWaveSurfer = () => {
      if (waveformRef.current) {
        const wavesurfer = WaveSurfer.create({
          container: waveformRef.current,
          waveColor: '#d4d4d4',
          progressColor: '#767676',
          cursorColor: '#f5f5f5',
          height: 56,
          barWidth: 3,
          barGap: 1,
          barRadius: 3,
          fillParent: true,
          normalize: true,
          dragToSeek: true,
        });

        wavesurferRef.current = wavesurfer;
      }
    };

    const fetchAudio = async () => {
      if (!url) return;

      try {
        const blob = await getCallRecording(url);

        if (!(blob instanceof Blob)) {
          throw new Error('Invalid response: Expected Blob');
        }

        const audioUrl = window.URL.createObjectURL(blob);

        const wavesurfer = wavesurferRef.current;

        if (wavesurfer) {
          wavesurfer.on('loading', (percentage: number) => {
            setLoadingPercentage(percentage);
          });

          wavesurfer.on('timeupdate', (time: number) => {
            setCurrentTime(time);
          });

          wavesurfer.on('ready', () => {
            setIsWaveSurferReady(true);
          });

          wavesurfer.on('finish', () => {
            setIsPlaying(false);
          });

          wavesurfer.load(audioUrl);
          wavesurferRef.current = wavesurfer;
        }
      } catch (error) {
        console.error('Error fetching audio:', error);
        setError('Error al cargar el audio');
      }
    };

    initializeWaveSurfer();
    fetchAudio();

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, [url]);

  const toggleAudio = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
      setIsPlaying(!isPlaying);
    }
  };

  const seekBackwards = () => {
    const currentTime = wavesurferRef.current?.getCurrentTime() || 0;
    const duration = wavesurferRef.current?.getDuration() || 0;
    const newTime = Math.max(currentTime - 2.5, 0);
    const percentage = newTime / duration;
    wavesurferRef.current?.seekTo(percentage);
  };

  const seekForward = () => {
    const currentTime = wavesurferRef.current?.getCurrentTime() || 0;
    const duration = wavesurferRef.current?.getDuration() || 0;
    const newTime = Math.min(currentTime + 5, duration);
    const percentage = newTime / duration;
    wavesurferRef.current?.seekTo(percentage);
  };

  const downloadAudio = async () => {
    if (!url) {
      toast.error('El audio no está disponible');
      return;
    }

    setIsDownloading(true);

    const blob = await getCallRecording(url);
    const audioUrl = window.URL.createObjectURL(blob);

    fetch(audioUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = 'call-recording.mp3';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      })
      .catch(() => {
        toast.error('Error al descargar el audio');
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <div className={'audio-player-detailed'}>
      <div className={'audio-player-detailed-waveform'}>
        {error ? (
          <div className={'error'}>
            <Text variant={'b3'} color={'var(--gray-2)'}>
              {error}
            </Text>
          </div>
        ) : (
          <>
            <div
              id={'waveform'}
              ref={waveformRef}
              style={{
                flex: 1,
                display: isWaveSurferReady ? 'block' : 'none',
                overflow: 'hidden',
              }}
            />
            <div
              className={'loading'}
              style={{ display: isWaveSurferReady ? 'none' : 'flex' }}
            >
              <Text variant={'b3'} color={'var(--gray-2)'}>
                Cargando... {loadingPercentage}%
              </Text>
            </div>
          </>
        )}
      </div>
      <div className={'audio-player-detailed-controls'}>
        <div className={'audio-player-detailed-controls-left'}>
          <IconButton
            iconName={isPlaying ? 'pause' : 'play'}
            iconSize={16}
            iconStyle={'fill'}
            isDisabled={!isWaveSurferReady}
            onClick={toggleAudio}
          />
          <IconButton
            iconName={'arrowUUpLeft'}
            iconStyle={'fill'}
            isDisabled={!isWaveSurferReady}
            onClick={seekBackwards}
          />
          <IconButton
            iconName={'arrowUUpRight'}
            iconStyle={'fill'}
            isDisabled={!isWaveSurferReady}
            onClick={seekForward}
          />
        </div>
        <div className={'audio-player-detailed-controls-right'}>
          <Text variant={'b2'} color={'var(--body)'}>
            {formatDuration(currentTime)} /{' '}
            {formatDuration(wavesurferRef.current?.getDuration() || 0)}
          </Text>
          <IconButton
            iconName={'download'}
            isDisabled={!isWaveSurferReady || isDownloading}
            isLoading={isDownloading}
            onClick={downloadAudio}
          />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayerDetailed;
