import React, { useState, useEffect } from 'react';
import { DataType, Table, useTable } from 'ka-table';
import { Column } from 'ka-table/models';
import { ActionType, SortingMode } from 'ka-table/enums';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import SearchInput from 'components/interface/searchInput';
import CampaignResultsDownload from '../campaignResultsDownload';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import {
  type CampaignData,
  type CampaignContact,
} from 'features/campaigns/providers/CampaignProvider';

import { formatNumber } from 'utils/numbers';

import './styles.css';

type CampaignResultsProps = {
  campaignId: string;
  canUpdate: boolean;
};

/**
 * CampaignResults
 * @description Component that displays the results of a campaign in a table
 * @param {string} campaignId - Campaign id
 * @param {boolean} canUpdate - If the user can update the campaign
 * @returns {TSX.Element} CampaignResults component
 */

const CampaignResults: React.FC<CampaignResultsProps> = ({
  campaignId,
  canUpdate,
}) => {
  const { chatbotsData } = useChatbot();
  const { campaignsData, getCampaignResults } = useCampaign();

  const campaignData: CampaignData = campaignsData[campaignId];

  const [campaignResultsArray, setCampaignResultsArray] = useState<
    CampaignContact[]
  >([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [sortBy, setSortBy] = useState('contact');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [extraColumns, setExtraColumns] = useState<
    Record<string, { key: string; title: string }>
  >({});
  const [isLoading, setIsLoading] = useState(false);

  const [tableKey, setTableKey] = useState(Date.now());

  const table = useTable({
    onDispatch: async (action) => {
      if (action.type === ActionType.UpdatePageIndex) {
        setPageIndex(action.pageIndex);
      }
      if (action.type === ActionType.UpdateSortDirection) {
        setSortBy(action.columnKey);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      }
    },
  });

  useEffect(() => {
    const campaignResultsAux: CampaignContact[] = [];
    const newExtraColumns = { ...extraColumns };

    campaignData?.campaignResults.forEach((result: any) => {
      const extractedData = result?.extractedData;
      const resultCopy = { ...result };

      for (const key in extractedData) {
        if (!newExtraColumns[key]) {
          resultCopy[key] = extractedData[key];
          newExtraColumns[key] = {
            key: key,
            title:
              chatbotsData[campaignData?.botId]?.extractableDataFields[key]
                ?.name,
          };
        } else {
          resultCopy[key] = extractedData[key];
        }
      }

      campaignResultsAux.push(resultCopy);
    });

    setExtraColumns(newExtraColumns);
    setCampaignResultsArray(campaignResultsAux);
    setTableKey(Date.now());
  }, [campaignData?.campaignResults, chatbotsData, campaignData?.botId]);

  useEffect(() => {
    const handleGetResults = async () => {
      if (isLoading) return;
      setIsLoading(true);

      await getCampaignResults(campaignId, pageIndex, pageSize, searchTerm);

      setIsLoading(false);
    };

    handleGetResults();
  }, [pageIndex, pageSize, searchTerm]);

  useEffect(() => {
    setPageCount(Math.ceil(campaignData?.campaignResultsCount / pageSize));
  }, [campaignData?.campaignResultsCount, pageSize]);

  return (
    <div>
      <div className={'campaign-results-header'}>
        <div className={'campaign-results-header-left'}>
          <div className={'campaign-results-header-left-icon'}>
            <Icon name={'phone'} size={18} color={'var(--primary)'} />
          </div>
          <Text variant={'h7'}>
            {formatNumber(campaignData?.campaignResultsCount)} Conversiones
            Registradas
          </Text>
        </div>
        <div className={'campaign-results-header-right'}>
          <SearchInput onChange={(e: any) => setSearchTerm(e.target.value)} />
          <CampaignResultsDownload campaignId={campaignId} />
        </div>
      </div>
      <div className={'campaign-results-table'}>
        <Table
          key={tableKey}
          table={table}
          rowKeyField={'id'}
          columns={[
            {
              key: 'fId',
              dataType: DataType.String,
              title: 'ID',
              style: { width: 80 },
            },
            {
              key: 'contact',
              dataType: DataType.String,
              title: 'Contacto',
              style: { width: 140 },
            },
            {
              key: 'name',
              dataType: DataType.String,
              title: 'Nombre',
              style: { width: 200 },
            },
            ...(Object.values(extraColumns) as Column[]),
          ]}
          data={campaignResultsArray}
          loading={{
            enabled: isLoading,
          }}
          paging={{
            enabled: true,
            pageIndex: pageIndex,
            pageSize: pageSize,
            pagesCount: pageCount,
          }}
          childComponents={{
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'fId':
                    return (
                      <Text variant={'b3'} numberOfLines={1}>
                        {props.value}
                      </Text>
                    );
                  case 'contact':
                    return (
                      <Text variant={'b3'} color={'var(--gray)'}>
                        {props.value}
                      </Text>
                    );
                  case 'name':
                    return (
                      <Text variant={'b3'} numberOfLines={1}>
                        {props.value}
                      </Text>
                    );
                  default:
                    return (
                      <Text
                        variant={'b3'}
                        numberOfLines={1}
                        formatter={
                          props.value?.type === 'number' ? 'number' : null
                        }
                      >
                        {props.value?.value}
                      </Text>
                    );
                }
              },
            },
          }}
          sortingMode={SortingMode.Single}
        />
      </div>
    </div>
  );
};

export default CampaignResults;
