import React from 'react';

import './styles.css';

type FontVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'h8'
  | 's1'
  | 's2'
  | 'b1'
  | 'b2'
  | 'b3'
  | 'b4'
  | 'b5'
  | 'label';

type TextProps = {
  variant?: FontVariants;
  size?: number;
  color?: string;
  align?: 'left' | 'center' | 'right';
  weight?: number;
  numberOfLines?: number;
  formatter?: 'number' | 'percentage' | null;
  decimalPlaces?: number;
  isLoading?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const parseTextForBold = (text: string) => {
  // Regex to match bold text
  const boldTextRegex = /\*\*(.*?)\*\*/g;
  const parts = [];

  let match;
  let lastIndex = 0;

  while ((match = boldTextRegex.exec(text)) !== null) {
    // If there is text before the match, push it as a regular text
    if (match.index > lastIndex) {
      parts.push({ text: text.slice(lastIndex, match.index), isBold: false });
    }
    // Push the matched text as bold text
    parts.push({ text: match[1], isBold: true });
    lastIndex = match.index + match[0].length;
  }

  // If there is text after the last match, push it as regular text
  if (lastIndex < text.length) {
    parts.push({ text: text.slice(lastIndex), isBold: false });
  }

  return parts;
};

/**
 * Text
 * @description Cutom text component
 * @param {String} variant - Font variant
 * @param {Number} size - Font size
 * @param {String} color - Font color
 * @param {String} align - Text align
 * @param {Number} weight - Font weight
 * @param {String} formatter - Formatter
 * @param {Number} decimalPlaces - Number of decimal places
 * @param {Number} numberOfLines - Number of lines to be displayed
 * @param {Boolean} isLoading - Loading to display skeleton loader
 * @param {Object} style - Custom style
 * @param {TSX.Element} children - Text to be displayed
 * @returns {TSX.Element} Text component
 */

const Text: React.FC<TextProps> = ({
  variant,
  align = 'left',
  size,
  color,
  weight,
  numberOfLines,
  formatter,
  decimalPlaces = 0,
  isLoading = false,
  style,
  children,
}) => {
  if (formatter === 'number') {
    children = new Intl.NumberFormat('en-US').format(children as number);
  }
  if (formatter === 'percentage') {
    children = `${((children ?? 0) as number).toFixed(decimalPlaces)}%`;
  }

  let content = children;
  if (typeof children === 'string') {
    const parts = parseTextForBold(children);
    content = parts.map((part, index) =>
      part.isBold ? (
        <b key={index} style={{ color: 'var(--body)', fontWeight: 500 }}>
          {part.text}
        </b>
      ) : (
        part.text
      ),
    );
  }

  return (
    <p
      className={`${variant} ${isLoading ? 'skeleton-loader' : ''}`}
      style={{
        color: color,
        fontSize: size,
        textAlign: align,
        fontWeight: weight,
        overflow: numberOfLines ? 'hidden' : 'visible',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        lineClamp: numberOfLines,
        WebkitLineClamp: numberOfLines,
        WebkitBoxOrient: 'vertical',
        ...style,
      }}
    >
      {isLoading ? '‎ ' : content ?? children}
    </p>
  );
};

export default Text;
