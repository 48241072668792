import React, { FC, createContext, useReducer, useMemo } from 'react';

import { UUID } from 'crypto';

import chatbotReducer from './chatbotReducer';

import { ExtractableDataFieldType } from '../enums';

export type ChatbotWhatsappIntegration = {
  id: string;
  chatbotId: UUID;
  whatsappAccountId: string;
  whatsappNumberId: string;
  whatsappSecretId: string;
  pageAccessToken: string;
  botActiveDefault: boolean;
};

export type ChatbotPhoneCallIntegration = {
  id: string;
  chatbotId: UUID;
  phoneNumber: string;
  phoneNumberId: string;
  voiceName: string;
  voiceId: string;
};

export type ChatbotSendableMedia = {
  id: string;
  name: string;
  description: string;
  file: string;
};

export type ChatbotKnowledgeChunk = {
  id: string;
  knowledgeSourceId: string;
  summary: string;
  content: string;
};

export type ChatbotKnowledgeSource = {
  id: string;
  chatbotId: UUID;
  name: string;
  type: string;
  file: string;
  lastUpdated: string;
  chunks: { [key: string]: ChatbotKnowledgeChunk };
};

export type ChatbotTag = {
  id: string;
  name: string;
  color: string;
  trigger: string;
  isConversion: boolean;
  isAuto: boolean;
  instructions: string;
  notifications: boolean;
};

export type ChatbotExtractableDataField = {
  id: string;
  chatbotId: UUID;
  name: string;
  description: string;
  type: ExtractableDataFieldType;
  enumValues: string[];
};

export type ChatbotAssignee = {
  userId: string;
  chatbotId: UUID;
  isEligible: boolean;
};

export type ChatbotData = {
  id: UUID;
  nickname: string;
  name: string;
  image: string;
  threshold: number;
  createdAt: string;
  isActive: boolean;
  personality: string;
  instructionsWhatsapp: string;
  instructionsPhoneCall: string;
  objective: string;
  businessName: string;
  businessDescription: string;
  knowledgeSources: { [key: string]: ChatbotKnowledgeSource };
  sendableMedia: { [key: string]: ChatbotSendableMedia };
  tags: { [key: string]: ChatbotTag };
  extractableDataFields: { [key: string]: ChatbotExtractableDataField };
  assignees: { [key: string]: ChatbotAssignee };
  whatsappIntegrations: { [key: string]: ChatbotWhatsappIntegration };
  phoneCallIntegrations: { [key: string]: ChatbotPhoneCallIntegration };
};

export type ChatbotsData = {
  [key: UUID]: ChatbotData;
};

export type ChatbotsState = {
  chatbotsData: ChatbotsData;
  saveChatbotsData: (chatbots: ChatbotsData) => void;
};

type ChatbotProviderProps = {
  children: React.ReactNode;
};

export const ChatbotContext = createContext<ChatbotsState>({
  chatbotsData: {},
  saveChatbotsData: () => {},
});

const ChatbotProvider: FC<ChatbotProviderProps> = ({ children }) => {
  const initialState: ChatbotsState = {
    chatbotsData: {},
    saveChatbotsData: (chatbots: ChatbotsData) => {
      return null;
    },
  };

  const [state, dispatch] = useReducer(chatbotReducer, initialState);

  const saveChatbotsData = (chatbotsData: ChatbotsData) => {
    dispatch({ type: 'SAVE_CHATBOTS_DATA', payload: chatbotsData });
  };

  const contextValue = useMemo(() => {
    return {
      chatbotsData: state.chatbotsData,
      saveChatbotsData,
    };
  }, [state.chatbotsData]);

  return (
    <ChatbotContext.Provider value={contextValue}>
      {children}
    </ChatbotContext.Provider>
  );
};

export default ChatbotProvider;
