import React, { useEffect, useState } from 'react';

import Icon from 'components/interface/icon';
import Button from 'components/interface/button';
import CampaignCard from 'features/dashboard/features/campaigns/components/campaignCard';
import EmptyState from 'components/interface/emptyState';

import { useUser } from 'features/user/hooks/useUser';
import { useCampaign } from 'features/campaigns/hooks/useCampaign';

import { type CampaignData } from 'features/campaigns/providers/CampaignProvider';

import './styles.css';

type CampaignGridProps = {
  campaigns: CampaignData[];
  isArchived?: boolean;
};

const CampaignGrid: React.FC<CampaignGridProps> = ({
  campaigns,
  isArchived = false,
}) => {
  const { userHasPermission } = useUser();
  const {
    getCampaignStats,
    updateCampaignArchive,
    exportCampaignContactsFile,
    exportCampaignCallsFile,
    exportCampaignResultsFile,
  } = useCampaign();

  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<string[]>([]);
  const [
    selectedCampaignsSameIntegrationType,
    setSelectedCampaignsSameIntegrationType,
  ] = useState<boolean>(false);

  const [canArchive, setCanArchive] = useState<boolean>(false);
  const [canDownload, setCanDownload] = useState<boolean>(false);

  useEffect(() => {
    getCampaignStats(campaigns.map((campaign) => campaign.id));
  }, [campaigns.length]);

  useEffect(() => {
    if (!isSelecting) {
      setSelectedCampaignIds([]);
      setSelectedCampaignsSameIntegrationType(false);
    }
  }, [isSelecting]);

  useEffect(() => {
    checkIfAllSelectedCampaignsAreSameIntegrationType();
  }, [selectedCampaignIds]);

  useEffect(() => {
    setCanArchive(selectedCampaignIds.length > 0);
    setCanDownload(
      selectedCampaignIds.length > 0 && selectedCampaignsSameIntegrationType,
    );
  }, [selectedCampaignIds, selectedCampaignsSameIntegrationType]);

  const checkIfAllSelectedCampaignsAreSameIntegrationType = () => {
    if (selectedCampaignIds.length === 0) {
      setSelectedCampaignsSameIntegrationType(false);
      return;
    }

    const selectedCampaigns = campaigns.filter((campaign) =>
      selectedCampaignIds.includes(campaign.id),
    );

    if (selectedCampaigns.length === 1) {
      setSelectedCampaignsSameIntegrationType(true);
      return;
    }

    const firstCampaignIntegrationType = selectedCampaigns[0].integrationType;
    const allSameType = selectedCampaigns.every(
      (campaign) => campaign.integrationType === firstCampaignIntegrationType,
    );

    setSelectedCampaignsSameIntegrationType(allSameType);
  };

  const handleSelect = (campaignId: string) => {
    setSelectedCampaignIds((prev) => [...prev, campaignId]);
  };

  const handleDeselect = (campaignId: string) => {
    setSelectedCampaignIds((prev) => prev.filter((id) => id !== campaignId));
  };

  const handleArchive = () => {
    updateCampaignArchive(selectedCampaignIds, !isArchived);
    setIsSelecting(false);
  };

  const handleDownloadContacts = () => {
    exportCampaignContactsFile(selectedCampaignIds);
    setIsSelecting(false);
  };

  const handleDownloadCalls = () => {
    exportCampaignCallsFile(selectedCampaignIds);
    setIsSelecting(false);
  };

  const handleDownloadResults = () => {
    exportCampaignResultsFile(selectedCampaignIds);
    setIsSelecting(false);
  };

  return (
    <>
      {userHasPermission('campaigns.update') && campaigns.length > 0 && (
        <div className={'campaign-grid-controls'}>
          <Button
            label={isSelecting ? 'Cancelar selección' : 'Hacer selección'}
            variant={'secondary'}
            onClick={() => setIsSelecting(!isSelecting)}
          />
          <Button
            label={isArchived ? 'Desarchivar' : 'Archivar'}
            icon={
              <Icon
                name={isArchived ? 'archive' : 'archiveTray'}
                size={16}
                color={canArchive ? 'var(--body)' : 'var(--pale)'}
              />
            }
            variant={'secondary'}
            isDisabled={!canArchive}
            onClick={handleArchive}
          />
          <Button
            label={'Descargar'}
            icon={
              <Icon
                name={'download'}
                size={16}
                color={canDownload ? 'var(--body)' : 'var(--pale)'}
              />
            }
            variant={'secondary'}
            isDisabled={!canDownload}
            options={[
              {
                label: 'Descargar .CSV Contactos',
                onClick: handleDownloadContacts,
              },
              {
                label: 'Descargar .CSV Llamadas',
                onClick: handleDownloadCalls,
              },
              {
                label: 'Descargar .CSV Resultados',
                onClick: handleDownloadResults,
              },
            ]}
          />
        </div>
      )}
      <div className="campaign-grid-container">
        {campaigns.map((campaign) => {
          return (
            <CampaignCard
              key={campaign.id}
              campaign={campaign}
              isSelectable={isSelecting}
              isSelected={selectedCampaignIds.includes(campaign.id)}
              onSelect={() => handleSelect(campaign.id)}
              onDeselect={() => handleDeselect(campaign.id)}
            />
          );
        })}
      </div>
      {campaigns.length === 0 && (
        <div style={{ marginTop: 124 }}>
          <EmptyState text={'No hay campañas'} iconName={'megaphoneSimple'} />
        </div>
      )}
    </>
  );
};

export default CampaignGrid;
