import { capitalize } from './strings';

/**
 * Format datetime to string
 * @param {string} date - The date to format
 * @returns {string} The formatted date
 * @example
 * formatDateTime('2021-08-31T15:13:00.000Z')
 * returns '8/31/2021, 13:00'
 */
export const formatDateTime = (date: string) => {
  if (!date) return '-';

  const hour = new Date(date).toLocaleString('en-US', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  });
  return `${formatDate(date)} - ${hour}`;
};

/**
 * Format datetime with seconds to string
 * @param {string} date - The date to format
 * @returns {string} The formatted date
 * @example
 * formatDateTimeWithSeconds('2021-08-31T15:00:00.000Z')
 * returns '8/31/2021, 13:00:00'
 */
export const formatDateTimeWithSeconds = (date: string | undefined) => {
  if (!date) return '-';

  const hour = new Date(date).toLocaleString('es', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });

  return `${hour} ${formatDate(date)}`;
};

/**
 * Format date to string
 * @param {string} date - The date to format
 * @returns {string} The formatted date
 * @example
 * formatDate('2021-08-31T15:00:00.000Z')
 * returns '8/31/2021'
 */
export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('es');
};

/**
 * Format date to string with dashes
 * @param {string} date - The date to format
 * @returns {string} The formatted date
 * @example
 * formatDateDashes('2021-08-31T15:00:00.000Z')
 * returns '2021-08-31'
 * formatDateDashes('2021-08-31T05:00:00.000Z')
 * returns '2021-08-31'
 */
export const formatDateDashes = (s: string) => {
  const date = new Date(s);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0',
  )}-${String(date.getDate()).padStart(2, '0')}`;
};

/**
 * Format date to time string
 * @param {string} date - The date to format
 * @returns {string} The formatted date
 * @example
 * formatTime('2021-08-31T15:00:00.000Z')
 * returns '13:00'
 * formatTime('2021-08-31T05:00:00.000Z')
 * returns '5:00'
 * formatTime('2021-08-31T15:13:00.000Z')
 * returns '13:13'
 */
export const formatTime = (date: string) => {
  return new Date(date).toLocaleTimeString('en-US', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  });
};

/**
 * Format date to time or date string depending on today's date
 * @param {string} date - The date to format
 * @param {boolean} showTime - Whether to show the time or not
 * @returns {string} The formatted date
 * @example
 * today is 8/31/2021
 * formatTimeOrDate('2021-08-31T15:00:00.000Z')
 * returns '13:00'
 * formatTimeOrDate('2021-08-30T15:00:00.000Z')
 * returns '8/30/2021'
 * formatTimeOrDate('2021-08-29T15:00:00.000Z')
 * returns '8/29/2021'
 */
export const formatTimeOrDate = (date: string, showTime: boolean = false) => {
  const today = new Date();
  const dateToFormat = new Date(date);

  if (
    today.getDate() === dateToFormat.getDate() &&
    today.getMonth() === dateToFormat.getMonth() &&
    today.getFullYear() === dateToFormat.getFullYear()
  ) {
    return new Date(date).toLocaleTimeString('en-US', {
      hour12: false,
      hour: 'numeric',
      minute: 'numeric',
    });
  } else {
    return showTime ? formatDateTime(date) : formatDate(date);
  }
};

/**
 * Format datetime or date string depending on today's date
 * @param {string} date - The date to format
 * @returns {string} The formatted date
 * @example
 * today is 8/31/2021
 * formatDateTimeOrDate('2021-08-31T15:00:00.000Z')
 * returns 'hoy a las 13:00'
 * formatDateTimeOrDate('2021-08-30T15:00:00.000Z')
 * returns 'ayer a las 15:00'
 * formatDateTimeOrDate('2021-08-29T15:00:00.000Z')
 * returns '29/08/2021 a las 15:00'
 * formatDateTimeOrDate('2021-08-15T15:00:00.000Z')
 * returns '15/08/2021 a las 15:00'
 */
export const formatDateTimeOrDate = (date: string) => {
  const today = new Date();
  const dateToFormat = new Date(date);

  if (
    today.getDate() === dateToFormat.getDate() &&
    today.getMonth() === dateToFormat.getMonth() &&
    today.getFullYear() === dateToFormat.getFullYear()
  ) {
    return `hoy a las ${formatTime(date)}`;
  } else if (
    today.getDate() - 1 === dateToFormat.getDate() &&
    today.getMonth() === dateToFormat.getMonth() &&
    today.getFullYear() === dateToFormat.getFullYear()
  ) {
    return `ayer a las ${formatTime(date)}`;
  } else {
    return `${formatDate(date)} a las ${formatTime(date)}`;
  }
};

/**
 * Format date long
 * @param {string} date - The date to format
 * @returns {string} The formatted date
 * @example
 * formatDateTimeLong('2021-08-31T15:13:00.000Z')
 * returns '31 de agosto de 2021'
 * formatDateTimeLong('2021-08-02T15:13:00.000Z')
 * returns '02 de agosto de 2021'
 */
export const formatDateLong = (date: string) => {
  const dateToFormat = new Date(date);
  const day = dateToFormat.getDate();
  const month = dateToFormat.toLocaleString('es', { month: 'long' });
  const year = dateToFormat.getFullYear();

  return `${day} de ${month} de ${year}`;
};

/**
 * Format date long with weekday
 * @param {string} date - The date to format
 * @returns {string} The formatted date
 * @example
 * formatDateLongWithWeekday('2021-08-31T15:13:00.000Z')
 * returns 'Martes, 31 de agosto de 2021'
 */
export const formatDateLongWithWeekday = (date: string) => {
  const dateToFormat = new Date(date);
  const day = dateToFormat.getDate();
  const month = dateToFormat.toLocaleString('es', { month: 'long' });
  const year = dateToFormat.getFullYear();
  const weekday = capitalize(
    dateToFormat.toLocaleString('es', { weekday: 'long' }),
  );

  return `${weekday}, ${day} de ${month} de ${year}`;
};

/**
 * Format date variable depending on today's day (Today, Yesterday, day of the week, date)
 * @param {string} date - The date to format
 * @returns {string} The formatted date
 * @example
 * today is 8/31/2021
 * formatDateVariable('2021-08-31T15:00:00.000Z')
 * returns 'Hoy'
 * formatDateVariable('2021-08-30T15:00:00.000Z')
 * returns 'Ayer'
 * formatDateVariable('2021-08-29T15:00:00.000Z')
 * returns 'Domingo'
 * formatDateVariable('2021-08-15T15:00:00.000Z')
 * returns '15/08/2021'
 */
export function formatDateVariable(dateString: string) {
  const today = new Date();
  const date = new Date(dateString);

  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  const dayDifference = (today.getTime() - date.getTime()) / (1000 * 3600 * 24);
  const daysOfWeek = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];

  if (dayDifference === 0) {
    return 'Hoy';
  } else if (dayDifference === 1) {
    return 'Ayer';
  } else if (dayDifference < 7 && dayDifference > 0) {
    return daysOfWeek[date.getDay()];
  } else {
    return formatDateLong(dateString);
  }
}

/**
 * Format date variable depending on today's day (00:00, Yesterday, day of the week, date)
 * @param {string} date - The date to format
 * @returns {string} The formatted date
 * @example
 * today is 8/31/2021
 * formatDateVariable('2021-08-31T15:00:00.000Z')
 * returns '15:00'
 * formatDateVariable('2021-08-30T15:00:00.000Z')
 * returns 'Ayer'
 * formatDateVariable('2021-08-29T15:00:00.000Z')
 * returns 'Domingo'
 * formatDateVariable('2021-08-15T15:00:00.000Z')
 * returns '15/08/2021'
 */
export function formatTimeVariable(dateString: string) {
  const today = new Date();
  const date = new Date(dateString);

  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  const dayDifference = (today.getTime() - date.getTime()) / (1000 * 3600 * 24);
  const daysOfWeek = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];

  if (dayDifference === 0) {
    return formatTime(dateString);
  } else if (dayDifference === 1) {
    return 'Ayer';
  } else if (dayDifference < 7 && dayDifference > 0) {
    return daysOfWeek[date.getDay()];
  } else {
    return formatDate(dateString);
  }
}

/**
 * Check if two dates are the same day
 * @param {string} a - The first date
 * @param {string} b - The second date
 * @returns {boolean} Whether the dates are the same day or not
 * @example
 * isSameDay('2021-08-31T15:00:00.000Z', '2021-08-31T15:00:00.000Z')
 * returns true
 * isSameDay('2021-08-31T15:00:00.000Z', '2021-08-30T15:00:00.000Z')
 * returns false
 */
export const isSameDay = (a: string, b: string) => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  return (
    dateA.getDate() === dateB.getDate() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear()
  );
};

/**
 * Display time since a date
 * @param {string} date - The date to display time since
 * @returns {string} The time since the date
 * @example
 * current date is 8/31/2021 and time is 15:00
 * timeSince('2021-08-31T15:00:00.000Z')
 * returns 'hace unos segundos'
 * timeSince('2021-08-31T14:59:00.000Z')
 * returns 'hace 1 minuto'
 * timeSince('2021-08-31T14:00:00.000Z')
 * returns 'hace 1 hora'
 * timeSince('2021-08-30T15:00:00.000Z')
 * returns 'hace 1 día'
 * timeSince('2021-08-15T15:00:00.000Z')
 * returns 'hace 2 semanas'
 * timeSince('2021-07-31T15:00:00.000Z')
 * returns 'hace 1 mes'
 * timeSince('2020-08-31T15:00:00.000Z')
 * returns 'hace 1 año'
 */
export const timeSince = (date: string) => {
  const seconds = Math.floor(
    (new Date().getTime() - new Date(date).getTime()) / 1000,
  );
  let interval = seconds / 31536000;

  if (interval > 1) {
    return `hace ${Math.floor(interval)} año${
      Math.floor(interval) > 1 ? 's' : ''
    }`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `hace ${Math.floor(interval)} mes${
      Math.floor(interval) > 1 ? 'es' : ''
    }`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `hace ${Math.floor(interval)} día${
      Math.floor(interval) > 1 ? 's' : ''
    }`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `hace ${Math.floor(interval)} hora${
      Math.floor(interval) > 1 ? 's' : ''
    }`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `hace ${Math.floor(interval)} minuto${
      Math.floor(interval) > 1 ? 's' : ''
    }`;
  }
  return 'hace unos segundos';
};

/**
 * Format duration in seconds to string with minutes and seconds, hours optionally
 * @param {number} duration - The duration to format
 * @param {boolean} showHours - Whether to show the hours or not
 * @returns {string} The formatted duration
 * @example
 * formatDuration(30)
 * returns '00:30'
 * formatDuration(30, true)
 * returns '00:00:30'
 * formatDuration(60)
 * returns '01:00'
 * formatDuration(60, true)
 * returns '01:00:00'
 * formatDuration(3600)
 * returns '01:00:00'
 * formatDuration(3600, true)
 * returns '01:00:00'
 */
export const formatDuration = (
  duration: number,
  showHours: boolean = false,
) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60)
    .toString()
    .padStart(2, '0');

  if (showHours || hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } else {
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  }
};

/**
 * Constants for days of the week
 */
export const daysOfTheWeekToNumber = {
  mon: 0,
  tue: 1,
  wed: 2,
  thu: 3,
  fri: 4,
  sat: 5,
  sun: 6,
};

/**
 * Constants for days of the week translated to Spanish (short)
 */
export const daysOfTheWeekToSpanish = {
  mon: 'Lun',
  tue: 'Mar',
  wed: 'Mié',
  thu: 'Jue',
  fri: 'Vie',
  sat: 'Sáb',
  sun: 'Dom',
};

/**
 * Constants for days of the week translated to Spanish (long)
 */
export const daysOfTheWeekToSpanishLong = {
  mon: 'Lunes',
  tue: 'Martes',
  wed: 'Miércoles',
  thu: 'Jueves',
  fri: 'Viernes',
  sat: 'Sábado',
  sun: 'Domingo',
};

/**
 * Get all timezones
 * @returns {string[]} The list of timezones
 * @example
 * getTimezones()
 * returns ['Africa/Abidjan', 'Africa/Accra', ...]
 */
export const getTimezones = () => {
  return [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg',
  ];
};
