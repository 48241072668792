import React, { useState, useEffect } from 'react';

import Hr from 'components/interface/hr';
import Text from 'components/interface/text';
import Icon from 'components/interface/icon';

import { IconName } from 'components/interface/icon';

import './styles.css';

export type HoverMenuAnchor =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

export type HoverMenuOption = {
  label: string;
  icon?: IconName;
  onClick: () => void;
};

type HoverMenuProps = {
  isOpen?: boolean;
  size?: 'small' | 'regular';
  options: HoverMenuOption[];
  anchor?: HoverMenuAnchor;
  bottomOption?: HoverMenuOption;
};

/**
 * HoverMenu
 * @description Custom hover menu component
 * @param {Boolean} isOpen - Hover menu open
 * @param {String} size - Hover menu size
 * @param {HoverMenuOption[]} options - Hover menu options
 * @param {HoverMenuOption} bottomOption - Hover menu bottom option
 * @param {HoverMenuAnchor} anchor - Hover menu anchor
 * @returns {TSX.Element} HoverMenu component
 */

const HoverMenu: React.FC<HoverMenuProps> = ({
  isOpen,
  size = 'regular',
  options,
  anchor = 'top-left',
  bottomOption,
}) => {
  const [hoveredOption, setHoveredOption] = useState<number | null>(null);

  return (
    <div
      className={`hover-menu-container hover-menu-container-${anchor} ${
        isOpen ? 'visible' : 'hidden'
      }`}
    >
      <div
        className={`hover-menu ${
          isOpen ? 'hover-menu-fade-in visible' : 'hover-menu-fade-in'
        }`}
      >
        {options.map((option: HoverMenuOption, index: number) => {
          const isHovered = hoveredOption === index;
          return (
            <div
              key={index}
              className={`hover-menu-option hover-menu-option-${size}`}
              onMouseEnter={() => setHoveredOption(index)}
              onMouseLeave={() => setHoveredOption(null)}
              onClick={option.onClick}
            >
              {option.icon && (
                <div className="hover-menu-option-icon">
                  <Icon
                    name={option.icon}
                    size={16}
                    color={isHovered ? 'var(--body)' : 'var(--gray)'}
                  />
                </div>
              )}
              <Text
                variant={'h8'}
                color={isHovered ? 'var(--body)' : 'var(--gray)'}
                size={13}
                numberOfLines={1}
              >
                {option.label}
              </Text>
            </div>
          );
        })}
        {bottomOption ? (
          <>
            <Hr marginTop={0} marginBottom={0} />
            <div
              className={`hover-menu-option hover-menu-option-${size}`}
              onMouseEnter={() => setHoveredOption(-1)}
              onMouseLeave={() => setHoveredOption(null)}
              onClick={bottomOption.onClick}
            >
              {bottomOption.icon && (
                <div className="hover-menu-option-icon">
                  <Icon
                    name={bottomOption.icon}
                    size={16}
                    color={hoveredOption === -1 ? 'var(--body)' : 'var(--gray)'}
                  />
                </div>
              )}
              <Text
                variant={'h8'}
                color={hoveredOption === -1 ? 'var(--body)' : 'var(--gray)'}
                size={13}
                numberOfLines={1}
              >
                {bottomOption.label}
              </Text>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default HoverMenu;
