import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import { UUID } from 'crypto';

import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Select from 'components/interface/select';
import Button from 'components/interface/button';
import TextArea from 'components/interface/textArea';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type ChatbotExtractableDataField } from 'features/chatbots/providers/ChatbotProvider';

type FormInputs = {
  name: string;
  description: string;
  type: string;
  enumValues: string;
};

type ExtractableDataFieldFormProps = {
  chatbotId: UUID;
  extractableDataField?: ChatbotExtractableDataField | null;
  onSubmitCallback: () => void;
};

const ExtractableDataFieldForm: React.FC<ExtractableDataFieldFormProps> = ({
  chatbotId,
  extractableDataField,
  onSubmitCallback,
}) => {
  const {
    createChatbotExtractableDataField,
    updateChatbotExtractableDataField,
    eliminateChatbotExtractableDataField,
  } = useChatbot();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormInputs>();

  const fieldType = watch('type');

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    // Send request to API conditional to create or update
    const response = extractableDataField
      ? await updateChatbotExtractableDataField(
          chatbotId,
          extractableDataField.id,
          data.name,
          data.description,
          data.type,
          data.enumValues,
        )
      : await createChatbotExtractableDataField(
          chatbotId,
          data.name,
          data.description,
          data.type,
          data.enumValues,
        );

    // Manage response errors or success
    if (response?.errors) {
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors.name,
        });
      }
      if (response.errors?.description) {
        setError('description', {
          type: 'manual',
          message: response.errors.description,
        });
      }
      if (response.errors?.type) {
        setError('type', {
          type: 'manual',
          message: response.errors.type,
        });
      }
      if (response.errors?.enumValues) {
        setError('enumValues', {
          type: 'manual',
          message: response.errors.enumValues,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar el campo');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Campo guardado correctamente');
      } else {
        toast.error('Error al guardar el campo');
        reset();
      }
      onSubmitCallback();
    }
    setIsLoading(false);
  };

  const onDelete = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const response = await eliminateChatbotExtractableDataField(
      chatbotId,
      extractableDataField?.id ?? '',
    );
    if (response?.errors) {
      response.errors?.non_field_errors.map((error: string) => {
        toast.error(error);
      });
    } else {
      if (response?.data?.success) {
        toast.success('El campo se eliminó correctamente');
      } else {
        toast.error('Error al eliminar el campo');
      }
      onSubmitCallback();
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: -16 }}>
      <Text variant={'s1'}>Nombre</Text>
      <Input
        placeholder={'Nombre del campo'}
        defaultValue={extractableDataField?.name ?? ''}
        error={errors.name?.message}
        {...register('name')}
      />
      <Text variant={'s1'}>Tipo</Text>
      <Select
        options={[
          { value: 'text', label: 'Texto' },
          { value: 'number', label: 'Número' },
          { value: 'date', label: 'Fecha (DD-MM-YYYY)' },
          { value: 'boolean', label: 'Booleano (Verdadero/Falso)' },
          { value: 'enum', label: 'Enum (Lista de opciones)' },
        ]}
        placeholder={'Selecciona un tipo'}
        defaultValue={extractableDataField?.type ?? 'text'}
        error={errors.type?.message}
        {...register('type')}
      />
      <Text variant={'s1'}>Descripción</Text>
      <TextArea
        placeholder={'Descripción del campo'}
        defaultValue={extractableDataField?.description ?? ''}
        error={errors.description?.message}
        {...register('description')}
      />
      {fieldType === 'enum' ||
      (!fieldType && extractableDataField?.type === 'enum') ? (
        <>
          <Text variant={'s1'}>Opciones (Separar por comas)</Text>
          <TextArea
            placeholder={'opcion1,opcion2,opcion3'}
            defaultValue={extractableDataField?.enumValues ?? ''}
            error={errors.enumValues?.message}
            {...register('enumValues')}
          />
        </>
      ) : null}
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
        {extractableDataField ? (
          <Button
            label={'Eliminar'}
            variant={'tertiary'}
            requiresConfirmation={true}
            confirmationTitle={'Eliminar campo'}
            confirmationText={'¿Estás seguro que deseas eliminar este campo?'}
            onClick={onDelete}
          />
        ) : null}
        <Button
          label={'Cancelar'}
          variant={'secondary'}
          onClick={() => {
            onSubmitCallback();
            reset();
          }}
        />
        <Button
          type={'submit'}
          label={'Guardar'}
          variant={'primary'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default ExtractableDataFieldForm;
