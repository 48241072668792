import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import * as Sentry from '@sentry/react';

import { BrowserRouter } from 'react-router-dom';

Sentry.init({
  dsn: 'https://1b57a94db392f4f5ec18faeb0116426e@o4505941283962880.ingest.us.sentry.io/4508525649854464',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate:
    process.env.REACT_APP_ENVIRONMENT === 'production' ? 0.2 : 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/api\.altur\.io/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // </React.StrictMode>
);
