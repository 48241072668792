import React, { useState, useEffect } from 'react';

import { Table } from 'ka-table';
import { DataType, EditingMode, SortingMode } from 'ka-table/enums';

import Text from 'components/interface/text';
import Modal from 'components/interface/modal';
import ExtractableDataFieldForm from 'features/dashboard/features/chatbots/forms/ExtractableDataFieldForm';
import TagActions from 'features/dashboard/features/chatbots/components/tagActions';
import ExtractableDataFieldCreateButton from 'features/dashboard/features/chatbots/components/ExtractableDataFieldCreateButton';
import SettingsFields from 'components/interface/settingsFields';
import EmptyState from 'components/interface/emptyState';

import { useUser } from 'features/user/hooks/useUser';

import { type ChatbotExtractableDataField } from 'features/chatbots/providers/ChatbotProvider';
import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';

import 'ka-table/style.css';

type ExtractableDataFieldsViewProps = {
  chatbotData: ChatbotData;
};

const ExtractableDataFieldsView: React.FC<ExtractableDataFieldsViewProps> = ({
  chatbotData,
}) => {
  const { userHasPermission } = useUser();
  const [columns, setColumns] = useState<any[]>([]);
  const [openExtractableDataField, setOpenExtractableDataField] =
    useState<ChatbotExtractableDataField | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const columns: any[] = [
      {
        key: 'name',
        title: `Campo`,
        style: {
          width: '240px',
        },
        dataType: DataType.Object,
      },
      {
        key: 'type',
        title: `Tipo`,
        style: {
          width: '96px',
        },
        dataType: DataType.String,
      },
      {
        key: 'description',
        title: `Descripción`,
        dataType: DataType.String,
      },
    ];
    if (
      userHasPermission('bots.all.update') ||
      userHasPermission(`bots.${chatbotData.id}.update`)
    ) {
      columns.push({
        key: 'actions',
        title: '',
        width: '72',
        dataType: DataType.String,
      });
    }
    setColumns(columns);
  }, []);

  useEffect(() => {
    if (!isModalOpen) setOpenExtractableDataField(null);
  }, [isModalOpen]);

  const handleOpenModal = (
    extractableDataField?: ChatbotExtractableDataField,
  ) => {
    setOpenExtractableDataField(extractableDataField ?? null);
    setIsModalOpen(true);
  };

  const ExtractableDataFieldsTable = () => {
    const chatbotExtractableDataFields: { [key: string]: Object }[] = [];

    Object.keys(chatbotData.extractableDataFields).forEach((key: string) => {
      chatbotExtractableDataFields.push({
        name: chatbotData.extractableDataFields[key].name,
        type: chatbotData.extractableDataFields[key].type,
        description: chatbotData.extractableDataFields[key].description,
        actions: key,
      });
    });

    return (
      <>
        <Table
          columns={columns}
          data={chatbotExtractableDataFields}
          rowKeyField={'actions'}
          editingMode={EditingMode.None}
          sortingMode={SortingMode.Single}
          childComponents={{
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'name':
                    return <Text variant={'b3'}>{props.value}</Text>;
                  case 'type':
                    return (
                      <Text variant={'b3'} color={'var(--gray)'}>
                        {props.value}
                      </Text>
                    );
                  case 'description':
                    return (
                      <Text variant={'b3'} color={'var(--gray)'}>
                        {props.value}
                      </Text>
                    );
                  case 'actions':
                    return (
                      <TagActions
                        id={props.value}
                        callback={() =>
                          handleOpenModal(
                            chatbotData.extractableDataFields[props.value],
                          )
                        }
                      />
                    );
                  default:
                    return props.value;
                }
              },
            },
          }}
        />
        {chatbotExtractableDataFields.length === 0 && (
          <EmptyState text={'No hay campos configurados'} iconName={'tag'} />
        )}
      </>
    );
  };

  const fields = {
    extractableDataFields: {
      label: 'Extracción de datos',
      description:
        'Define campos de datos los cuales el asistente intentará extraer al final de cada conversación, esto con la finalidad de automatizar procesos de captura de datos.',
      options: [
        <ExtractableDataFieldCreateButton
          chatbotId={chatbotData.id}
          callback={() => {
            setOpenExtractableDataField(null);
            handleOpenModal();
          }}
        />,
      ],
      component: <ExtractableDataFieldsTable />,
    },
  };

  return (
    <>
      <SettingsFields fields={fields} />
      {userHasPermission('bots.all.update') ||
      userHasPermission(`bots.${chatbotData.id}.update`) ? (
        <Modal
          isOpen={isModalOpen}
          title={
            openExtractableDataField
              ? 'Editar campo'
              : 'Crear campo de extracción de datos'
          }
          onClose={() => setIsModalOpen(false)}
        >
          <ExtractableDataFieldForm
            chatbotId={chatbotData.id}
            extractableDataField={openExtractableDataField}
            onSubmitCallback={() => {
              setIsModalOpen(false);
            }}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default ExtractableDataFieldsView;
