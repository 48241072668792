import React, { useState } from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import EndUserPicture from '../endUserPicture';
import MessageStatusIcon from 'features/inbox/components/messageStatusIcon';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type Thread } from 'features/inbox/types/inboxTypes';
import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';
import { type IconName } from 'components/interface/icon';

import { formatTimeVariable } from 'utils/dates';
import { displayIntegrationIcon } from 'utils/integrations';

import './styles.css';

const messageTypeIcons = {
  image: 'image',
  document: 'file',
  template: 'cards',
};

/**
 * InboxThread
 * @description Component to display thread in threads section
 * @param {Thread} thread - thread object
 * @param {boolean} isStandalone - flag to display thread as standalone
 * @returns {TSX.Element} InboxThread component
 */

type InboxThreadProps = {
  thread: Thread;
  isStandalone?: boolean;
};

const InboxThread: React.FC<InboxThreadProps> = ({
  thread,
  isStandalone = false,
}) => {
  const { inbox, openThread } = useInbox();
  const { chatbotsData } = useChatbot();

  const [chatbot] = useState<ChatbotData>(chatbotsData[thread.chatbotId]);

  return (
    <div
      className={`inbox-thread ${
        isStandalone ? 'inbox-thread-standalone' : ''
      }`}
      style={isStandalone ? { border: 'none' } : {}}
      onClick={() => openThread(thread.id)}
    >
      {inbox.activeThread?.id === thread.id ? (
        <div className={'inbox-thread-active'} />
      ) : null}
      <div className={'inbox-thread-header'}>
        <div style={{ margin: 8, marginRight: 4 }}>
          <EndUserPicture endUser={thread.endUser} size={36} />
        </div>
        <div className={'inbox-thread-header-info'}>
          <div className={'inbox-thread-chatbot'}>
            <Icon
              name={
                displayIntegrationIcon[thread.integrationType]?.name as IconName
              }
              color={displayIntegrationIcon[thread.integrationType]?.color}
              style={'fill'}
              size={12}
            />
            <Text
              variant={'b3'}
              size={11}
              color={'var(--gray)'}
              numberOfLines={1}
            >
              {chatbot?.nickname}
            </Text>
          </div>
          <div className={'inbox-thread-enduser'}>
            <Text variant={'b2'} numberOfLines={1}>
              {thread.endUser?.displayName || 'Anónimo'}
            </Text>
          </div>
        </div>
        <div className={'inbox-thread-header-updates'}>
          <Text variant={'b3'} size={11} color={'var(--gray)'}>
            {formatTimeVariable(thread.updatedAt)}
          </Text>
          {thread.unreadMessages <= 0 ? null : (
            <div className={'inbox-thread-header-updates-unread'}>
              <Text variant={'b3'} size={11} color={'var(--white)'}>
                {thread.unreadMessages}
              </Text>
            </div>
          )}
        </div>
      </div>
      <div
        className={`inbox-thread-body ${
          isStandalone ? 'inbox-thread-body-standalone' : ''
        }`}
      >
        {thread.latestMessage.sentBy === 'AI' ? (
          <MessageStatusIcon status={thread.latestMessage.fStatus} />
        ) : (
          <></>
        )}

        {thread.latestMessage.type === 'template' ||
        thread.latestMessage.type === 'image' ||
        thread.latestMessage.type === 'document' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Icon
              name={messageTypeIcons[thread.latestMessage.type] as IconName}
              color={'var(--gray)'}
              size={14}
            />
            <Text
              variant={'b3'}
              color={'var(--gray)'}
              style={{ textTransform: 'capitalize' }}
              numberOfLines={2}
            >
              {thread.latestMessage.type}
            </Text>
          </div>
        ) : (
          <Text variant={'b3'} color={'var(--gray)'} numberOfLines={2}>
            {thread.latestMessage.content}
          </Text>
        )}
      </div>
      {!isStandalone ? (
        <div className={'inbox-thread-tags'}>
          {thread.tags.map((tag) => (
            <div
              key={tag}
              className={'inbox-thread-tag'}
              style={{
                backgroundColor: `var(--tag-${chatbot.tags[tag]?.color}-pale)`,
                borderColor: `var(--tag-${chatbot.tags[tag]?.color})`,
              }}
            >
              <Icon
                name={'tag'}
                size={12}
                color={`var(--tag-${chatbot.tags[tag]?.color})`}
              />
              <Text
                variant={'b3'}
                size={11}
                color={`var(--tag-${chatbot.tags[tag]?.color})`}
              >
                {chatbot.tags[tag]?.name}
              </Text>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default InboxThread;
