import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/interface/button';
import TabMenu from 'components/interface/tabMenu';
import CampaignGrid from 'features/dashboard/features/campaigns/components/campaignGrid';
import PageTitle from 'features/dashboard/components/pageTitle';

import { useUser } from 'features/user/hooks/useUser';
import { useCampaign } from 'features/campaigns/hooks/useCampaign';

import { type CampaignData } from 'features/campaigns/providers/CampaignProvider';
import Icon, { type IconName } from 'components/interface/icon';

const CampaignsView: React.FC = () => {
  const navigate = useNavigate();
  const { userHasPermission } = useUser();
  const { campaignsData } = useCampaign();
  const [campaigns, setCampaigns] = useState<CampaignData[]>([]);
  const [archivedCampaigns, setArchivedCampaigns] = useState<CampaignData[]>(
    [],
  );

  useEffect(() => {
    if (campaignsData) {
      const campaignList: CampaignData[] = Object.values(campaignsData)
        .filter((campaign) => !campaign.archived)
        .sort((a, b) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });
      const archivedCampaignsList: CampaignData[] = Object.values(campaignsData)
        .filter((campaign) => campaign.archived)
        .sort((a, b) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });

      setCampaigns(campaignList);
      setArchivedCampaigns(archivedCampaignsList);
    }
  }, [campaignsData]);

  const tabs = {
    campaigns: {
      label: 'Campañas',
      iconName: 'megaphoneSimple' as IconName,
      component: <CampaignGrid campaigns={campaigns} />,
    },
    archived: {
      label: 'Campañas archivadas',
      iconName: 'archive' as IconName,
      component: (
        <CampaignGrid campaigns={archivedCampaigns} isArchived={true} />
      ),
    },
  };

  return (
    <div className={'dashboard-page'}>
      <PageTitle
        title={'Campañas de difusión'}
        iconName={'megaphoneSimple'}
        button={
          userHasPermission('campaigns.create') && (
            <div style={{ marginTop: -16 }}>
              <Button
                variant={'primary'}
                label={'Nueva campaña'}
                icon={<Icon name={'plus'} size={16} color={'var(--white)'} />}
                onClick={() => navigate('/dashboard/campaigns/new')}
              />
            </div>
          )
        }
      />
      <TabMenu tabs={tabs} />
    </div>
  );
};

export default CampaignsView;
