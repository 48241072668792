import React, { useState } from 'react';

import Icon from 'components/interface/icon';
import Button from 'components/interface/button';

import { useUser } from 'features/user/hooks/useUser';

type ExtractableDataFieldCreateButtonProps = {
  chatbotId: string;
  callback?: () => void;
};

const ExtractableDataFieldCreateButton: React.FC<
  ExtractableDataFieldCreateButtonProps
> = ({ chatbotId, callback = () => {} }) => {
  const { userHasPermission } = useUser();

  if (
    !userHasPermission('bots.all.update') &&
    !userHasPermission(`bots.${chatbotId}.update`)
  )
    return null;

  return (
    <>
      <Button
        variant={'secondary'}
        label={'Nuevo campo'}
        icon={<Icon name={'plusSquare'} size={18} />}
        onClick={callback}
      />
    </>
  );
};

export default ExtractableDataFieldCreateButton;
