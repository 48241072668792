import React from 'react';

import Text from 'components/interface/text';
import IconButton from 'components/interface/iconButton';

import './styles.css';

type SheetProps = {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

/**
 * Sheet
 * @description Description of Sheet
 * @param {string} title - Title of the sheet
 * @param {boolean} isOpen - Whether the sheet is open
 * @param {function} onClose - Function to close the sheet
 * @param {React.ReactNode} children - Children of the sheet
 * @returns {TSX.Element} Sheet component
 */

const Sheet: React.FC<SheetProps> = ({
  title = '',
  isOpen,
  onClose,
  children,
}) => {
  return (
    <div className={`sheet ${isOpen ? 'sheet-open' : ''}`}>
      <div
        className={`sheet-overlay ${isOpen ? 'sheet-overlay-open' : ''}`}
        onClick={onClose}
      />
      <div className={`sheet-content ${isOpen ? 'sheet-content-open' : ''}`}>
        <div className={'sheet-content-header'}>
          <Text variant={'h6'}>{title}</Text>
          <IconButton iconName={'close'} onClick={onClose} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Sheet;
