import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import CampaignCardOptions from 'features/dashboard/features/campaigns/components/campaignCardOptions';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';
import { useUser } from 'features/user/hooks/useUser';

import { type CampaignData } from 'features/campaigns/providers/CampaignProvider';

import {
  CAMPAIGN_INTEGRATION_TYPE_PHONECALL,
  CAMPAIGN_INTEGRATION_TYPE_WHATSAPP,
} from 'features/campaigns/providers/CampaignProvider';

import './styles.css';

type CampaignCompletion = {
  contactsReachedPercentage: number;
  contactsReached: number;
  contacts: number;
};

type CampaignCardProps = {
  campaign: CampaignData;
  isSelectable: boolean;
  isSelected: boolean;
  onSelect: () => void;
  onDeselect: () => void;
};

const CampaignCard: React.FC<CampaignCardProps> = ({
  campaign,
  isSelectable,
  isSelected,
  onSelect,
  onDeselect,
}) => {
  const navigate = useNavigate();
  const { chatbotsData } = useChatbot();
  const { userHasPermission } = useUser();

  const [campaignIntegration, setCampaignIntegration] =
    useState<string>('WhatsApp');
  const [campaignCompletion, setCampaignCompletion] =
    useState<CampaignCompletion>({
      contactsReachedPercentage: 0,
      contactsReached: 0,
      contacts: 0,
    });
  const [formattedDate, setFormattedDate] = useState<string>('');

  useEffect(() => {
    if (campaign.integrationType === CAMPAIGN_INTEGRATION_TYPE_WHATSAPP) {
      setCampaignIntegration('WhatsApp');
      setCampaignCompletion({
        contactsReachedPercentage: campaign.campaignStats?.completionRate ?? 0,
        contactsReached: campaign.campaignStats?.completed ?? 0,
        contacts: campaign.campaignStats?.contacts ?? 0,
      });
    }
    if (campaign.integrationType === CAMPAIGN_INTEGRATION_TYPE_PHONECALL) {
      setCampaignIntegration('Llamadas');
      setCampaignCompletion({
        contactsReachedPercentage:
          campaign.campaignStats?.contactsSentRate ?? 0,
        contactsReached: campaign.campaignStats?.contactsSent ?? 0,
        contacts: campaign.campaignStats?.contacts ?? 0,
      });
    }

    const date = new Date(campaign.createdAt);
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    setFormattedDate(formattedDate);
  }, [campaign.campaignStats]);

  const handleClick = () => {
    if (isSelectable) {
      if (isSelected) {
        onDeselect();
      } else {
        onSelect();
      }
    } else {
      navigate(`/dashboard/campaigns/${campaign.id}`);
    }
  };

  return (
    <div
      className={`campaign-card ${isSelectable ? 'selectable' : ''} ${
        isSelected ? 'selected' : ''
      }`}
      onClick={handleClick}
    >
      <div className={'campaign-card__header'}>
        <div>
          <Text
            variant={'s2'}
            weight={500}
            color={
              campaignIntegration === 'WhatsApp'
                ? 'var(--integration-whatsapp)'
                : 'var(--integration-phone-call)'
            }
          >
            {campaignIntegration}
          </Text>
          <Text variant={'h7'}>{campaign.name}</Text>
          <Text variant={'b3'} color={'var(--gray)'}>
            {campaign.description}
          </Text>
        </div>
        {userHasPermission('campaigns.update') && !isSelectable && (
          <CampaignCardOptions campaignId={campaign.id} />
        )}
        {isSelectable && (
          <div
            className={`campaign-card__selectable-indicator ${
              isSelected ? 'selected' : ''
            }`}
          >
            {isSelected && (
              <div className={'campaign-card__selectable-indicator__check'}>
                <div style={{ position: 'relative' }}>
                  <Icon
                    name={'check'}
                    size={12}
                    style={'bold'}
                    color={'var(--white)'}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={'campaign-card__body'}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <div className={'campaign-card__bot'}>
            <Icon name={'robot'} color={'var(--gray)'} />
            <Text variant={'b3'} weight={400} color={'var(--gray)'}>
              {chatbotsData[campaign.botId]?.nickname}
            </Text>
          </div>
          <div className={'campaign-card__bot'}>
            <Icon name={'calendarBlank'} color={'var(--gray)'} />
            <Text variant={'b3'} weight={400} color={'var(--gray)'}>
              {formattedDate}
            </Text>
          </div>
        </div>
        <div className={'campaign-card__completion'}>
          <div
            style={{
              width: `${campaignCompletion.contactsReachedPercentage}%`,
              backgroundColor: `${
                campaign.status === 'active' ? 'var(--success)' : 'var(--gray)'
              }`,
            }}
          />
        </div>
        <div className={'campaign-card__completion__text'}>
          <div className={'campaign-card__state'}>
            <div
              style={{
                backgroundColor: `var(--${campaign.status})`,
                borderColor: `var(--${campaign.status}-pale)`,
              }}
            ></div>
            <Text variant={'b3'} color={'var(--gray)'}>
              {campaign.status}
            </Text>
          </div>

          <Text variant={'b3'} weight={400} color={'var(--gray)'}>
            {campaignCompletion.contactsReached} / {campaignCompletion.contacts}
          </Text>
        </div>
      </div>
      <div className={'campaign-card__footer'}></div>
    </div>
  );
};

export default CampaignCard;
